import { makeStyles, Button, Input } from "@material-ui/core";

export const UploadImage = ({ id, onChange }) => {
  const classes = useStyles();

  return (
    <label htmlFor={`contained-button-file-${id}`}>
      <Input
        className={classes.upload}
        accept="image/*"
        id={`contained-button-file-${id}`}
        type="file"
        onChange={(event) => onChange(event.target.files[0])}
      />
      <Button variant="contained" component="span">
        העלאת תמונה
      </Button>
    </label>
  );
};

const useStyles = makeStyles({
  upload: {
    display: "none",
  },
});
