import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const CustomCard = ({ children, className }) => {
  const classes = useStyles();

  return <Card className={`${classes.card} ${className}`}>{children}</Card>;
};

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "5vh 1vw 5vh 1vw",
    padding: 10,
    textAlign: "center",
    backgroundColor: theme.palette.primary.light,
    border: "solid black",
    borderRadius: 25,
  },
}));
