import { TextField } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { UploadImage } from "../upload-image/upload-image";

export const RecommendBox = ({
  recommendation_title,
  recommendation_description,
  recommend_image,
  onChange,
}) => {
  const classes = useStyles();
  const handleChange = (key) => (event) => onChange(key, event.target.value);
  const handleImageChange = (key) => (value) => onChange(key, value);

  return (
    <div className={classes.main}>
      <img
        src={recommend_image && URL.createObjectURL(recommend_image)}
        alt="ממליץ"
        className={classes.picture}
      />
      <UploadImage
        onChange={handleImageChange("recommend_image")}
        id="recommendImage"
      />
      <TextField
        className={classes.title}
        value={recommendation_title}
        label="כותרת"
        onChange={handleChange("recommendation_title")}
        maxRows={5}
        multiline
      />
      <TextField
        className={classes.description}
        value={recommendation_description}
        label="תיאור"
        onChange={handleChange("recommendation_description")}
        maxRows={5}
        multiline
      />
    </div>
  );
};

const useStyles = makeStyles({
  upload: {
    display: "none",
  },
  main: {
    display: "flex",
    flexDirection: "column",
  },
  picture: {
    width: "30%",
    borderRadius: "50%",
  },
  description: {
    whiteSpace: "pre-wrap",
    padding: "5% 2% 5% 2%",
  },
  title: {
    whiteSpace: "pre-wrap",
    padding: "5% 2% 1% 2%",
  },
});
