import { useEffect, useState } from "react";
import { EditingCustomersRrecommendBox } from "./editing-customers-recommend-box";
import RecommendPrivateServices from "../../services/private-recommend";
import RecommendPublicServices from "../../services/public-recommend";
import IconButton from "@material-ui/core/IconButton";
import { CustomersRrecommendBox } from "../customers-recommend-box";
import DeleteIcon from "@material-ui/icons/Delete";

export const EditingCustomersRrecommend = () => {
  const [customersRecommend, setCustomersRecommend] = useState([]);

  const fetch = async () => {
    try {
      setCustomersRecommend(await RecommendPublicServices.fetchAll());
    } catch {
      alert("יש שגיאה");
    }
  };

  const handleRemove = async (recommendId) => {
    if (!!recommendId) await RecommendPrivateServices.remove(recommendId);

    fetch();
  };

  const handleCreate = async (recommend) => {
    try {
      const images = new FormData();
      images.append("return_image", recommend.return_image);
      images.append("recommend_image", recommend.recommend_image);

      const result = await RecommendPrivateServices.uploadImage(images);

      await RecommendPrivateServices.create({
        recommendation_title: recommend.recommendation_title,
        recommendation_description: recommend.recommendation_description,
        return_amount: recommend.return_amount,
        recommend_image_url: result.recommend_image_url,
        return_image_url: result.return_image_url,
      });

      fetch();
    } catch {
      alert("יש שגיאה");
    }
  };

  useEffect(() => {
    (async () => await fetch())();
  }, []);

  return (
    <>
      <EditingCustomersRrecommendBox
        key={"new"}
        onSave={handleCreate}
        customerRecommend={{}}
      />

      {customersRecommend.map((customerRecommend) => (
        <div key={customerRecommend.id}>
          <IconButton onClick={() => handleRemove(customerRecommend.id)}>
            <DeleteIcon fontSize="large" />
          </IconButton>
          <CustomersRrecommendBox customerRecommend={customerRecommend} />
        </div>
      ))}
    </>
  );
};
