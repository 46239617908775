import axios from "axios";
import { getHeaders } from "./headers";
import { serverUrl } from "./config";

const uri = serverUrl + "/private/users/";

const fetchAllUsers = async () => {
  const headers = getHeaders();

  const { data } = await axios.get(uri, { headers });

  return data;
};

const removeUser = async (id) => {
  const headers = getHeaders();

  const { data } = await axios.delete(uri + id, { headers });

  return data || [];
};

const exports = { fetchAllUsers, removeUser };

export default exports;
