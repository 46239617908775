import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

export const Title = ({ children, className }) => {
  const classes = useStyles();

  return (
    <Typography variant="h4" className={`${classes.textTitle} ${className}`}>
      <b>{children}</b>
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  textTitle: {
    color: theme.palette.primary.main,
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
  },
}));
