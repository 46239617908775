import axios from "axios";
import { getHeaders } from "./headers";
import { serverUrl } from "./config";

const uri = serverUrl + "/private/recommend/";

const fetchAll = async () => {
  const headers = getHeaders();

  const { data } = await axios.get(uri, { headers });

  return data || [];
};

const remove = async (id) => {
  const headers = getHeaders();

  const { data } = await axios.delete(uri + id, { headers });

  return data;
};

const update = async (recommend) => {
  const headers = getHeaders();

  const { data } = await axios.put(uri, recommend, { headers });

  return data;
};

const uploadImage = async (images) => {
  const headers = { ...getHeaders(), "Content-Type": "multipart/form-data" };

  const { data } = await axios.post(uri + "upload-images", images, { headers });

  return data;
};

const create = async (recommend) => {
  const headers = getHeaders();

  const { data } = await axios.post(uri, recommend, { headers });

  return data;
};

const exports = { fetchAll, remove, create, update, uploadImage };

export default exports;
