import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";

export const OurReturnBox = ({ title, children }) => {
  const classes = useStyles();

  return (
    <>
      <img
        src={"https://recommend-images.retroplus.co.il/" + children}
        alt="מסמך החזר"
        style={{ height: "100%", width: "100%" }}
      />
      <Box className={classes.box}>
        <Typography variant="body1">
          <b>{title}</b>
        </Typography>
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  box: {
    position: "relative",
    top: "-60%",
    width: "30%",
    right: "-30%",
    transform: "rotate(30deg)",
    border: "solid black",
    borderLeft: "none",
    borderRight: "none",
  },
});
