import { Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import UsersServices from "../../services/private-users";
import { User } from "./user";

export const Users = () => {
  const [users, setUsers] = useState([]);

  const handleRemove = async (id) => {
    await UsersServices.removeUser(id);
    await fetchUsers();
  };

  const fetchUsers = async () => {
    try {
      setUsers(await UsersServices.fetchAllUsers());
    } catch {
      alert("יש שגיאה");
    }
  };

  useEffect(() => {
    (async () => await fetchUsers())();

    const fetchUsersInterval = setInterval(() => {
      fetchUsers();
    }, 6 * 60 * 60 * 1000);

    return () => clearInterval(fetchUsersInterval);
  }, []);

  return (
    <Container
      maxWidth="xs"
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "3vh",
        boxShadow: "none",
      }}
    >
      {users.map((user) => (
        <User
          name={user.name}
          phoneNumber={user.phone_number}
          key={user.id}
          onRemove={() => handleRemove(user.id)}
        />
      ))}
    </Container>
  );
};
