import { Card, Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/Delete";

export const User = ({ name, phoneNumber, onRemove }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Typography className={classes.text} variant="body1">
        {name}
      </Typography>
      <Typography
        style={{ margin: "auto" }}
        variant="body1"
        className={classes.text}
      >
        {phoneNumber}
      </Typography>
      <IconButton onClick={onRemove}>
        <DeleteIcon />
      </IconButton>
    </Card>
  );
};

const useStyles = makeStyles({
  card: {
    display: "flex",
    flexDirection: "row",
  },
  text: {
    padding: "2vh",
  },
});
