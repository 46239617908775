import axios from "axios";
import { serverUrl } from "./config";

const uri = serverUrl + "/public/login/";

export const login = async (email, password) => {
  try {
    const { data } = await axios.post(uri, { email, password });

    if (data) {
      localStorage.setItem("user", data);

      return true;
    }
    return false;
  } catch {
    return false;
  }
};
