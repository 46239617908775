import Grid from "@material-ui/core/Grid";
import { CustomCard } from "../card";
import { Title } from "../custom-components/title";
import { OurReturnBox } from "./our-return-box";
import { RecommendBox } from "./recommend-box";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const CustomersRrecommendBox = ({ customerRecommend }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.main} justifyContent="center">
      <Grid
        item
        md={5}
        xs={12}
        className={`${classes.grid} ${classes.ourReturn}`}
      >
        <Title>ההחזרים שלנו</Title>
        <CustomCard className={`${classes.card} ${classes.ourReturnCard}`}>
          <OurReturnBox title={customerRecommend.return_amount}>
            {customerRecommend.return_image_url}
          </OurReturnBox>
        </CustomCard>
      </Grid>
      <Grid item md={5} xs={12} className={classes.grid}>
        <Title>לקוחות ממליצים</Title>
        <CustomCard className={`${classes.card} ${classes.recommendCard}`}>
          <RecommendBox
            title={customerRecommend.recommendation_title}
            picture={customerRecommend.recommend_image_url}
            description={customerRecommend.recommendation_description}
          />
        </CustomCard>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    top: "10vh",
    padding: "9vh 2vw 0vh 2vw",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30vh",
    },
  },
  grid: {
    textAlign: "center",
  },
  ourReturn: {
    marginTop: "23vh",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0vh",
    },
  },
  ourReturnCard: {
    height: "44vh",
  },
  card: {
    margin: "2vh 1vw 2vh 1vw",
  },
  recommendCard: {
    height: "67vh",
  },
}));
