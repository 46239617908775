import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { useState } from "react";
import { EditingCustomersRrecommend } from "../editing-customers-recommend/editing-customers-recommend";
import { Users } from "../users/users";

export const AdminPanel = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="משתמשים" value="1" />
            <Tab label="עריכת ממליצים" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Users />
        </TabPanel>
        <TabPanel value="2" style={{ padding: 0 }}>
          <EditingCustomersRrecommend />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
