import {
  jssPreset,
  StylesProvider,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@material-ui/core";
import { create } from "jss";
import rtl from "jss-rtl";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export let theme = createTheme({
  spacing: (factor) => `${factor * 1}vh`,
  palette: {
    primary: {
      main: "#ff9900",
      light: "rgba(243, 189, 39, 0.753)",
      dark: "#ff9900",
    },
    secondary: {
      main: "#000000",
      light: "rgb(245 202 82 / 50%)",
      dark: "#ff9900",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 15,
  },
  direction: "rtl",
});

theme = responsiveFontSizes(theme);

export const ImplementTheme = ({ children }) => {
  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StylesProvider>
  );
};
