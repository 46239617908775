import Grid from "@material-ui/core/Grid";
import { CustomCard } from "../card";
import { Title } from "../custom-components/title";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RecommendBox } from "./recommend-box";
import { OurReturnBox } from "./our-return-box";
import { useEffect, useState } from "react";
import { IconButton, Paper } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

export const EditingCustomersRrecommendBox = ({
  customerRecommend,
  onRemove,
  onSave,
}) => {
  const classes = useStyles();
  const [editedCustomerRecommend, setEditedCustomerRecommend] =
    useState(customerRecommend);

  const handleChange = (key, value) =>
    setEditedCustomerRecommend({ ...editedCustomerRecommend, [key]: value });

  useEffect(() => {
    setEditedCustomerRecommend(customerRecommend);
  }, [customerRecommend]);

  const handleSave = () => {
    onSave(editedCustomerRecommend);
  };

  return (
    <Paper>
      <Grid container justifyContent="center">
        <Grid item md={5} xs={12} className={classes.grid}>
          <Title>לקוחות ממליצים</Title>
          <CustomCard className={`${classes.card} ${classes.recommendCard}`}>
            <RecommendBox
              recommendation_title={
                editedCustomerRecommend?.recommendation_title || ""
              }
              recommend_image_url={editedCustomerRecommend?.recommend_image_url}
              recommend_image={editedCustomerRecommend?.recommend_image}
              recommendation_description={
                editedCustomerRecommend?.recommendation_description || ""
              }
              onChange={handleChange}
            />
          </CustomCard>
        </Grid>
        <Grid item md={5} xs={12} className={classes.grid}>
          <IconButton onClick={handleSave}>
            <SaveIcon fontSize="large" />
          </IconButton>
          <Title>ההחזרים שלנו</Title>
          <CustomCard className={`${classes.card} ${classes.ourReturnCard}`}>
            <OurReturnBox
              return_image={editedCustomerRecommend?.return_image}
              return_image_url={editedCustomerRecommend?.return_image_url}
              return_amount={editedCustomerRecommend?.return_amount || ""}
              onChange={handleChange}
            />
          </CustomCard>
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles({
  grid: {
    textAlign: "center",
  },
  ourReturnCard: {
    height: "44vh",
  },
  card: {
    margin: "2vh 1vw 2vh 1vw",
  },
  recommendCard: {
    // height: "67vh",
  },
});
