import "./App.css";
import { ImplementTheme } from "./components";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Logo } from "./images";
import { Login } from "./components";
import { AdminPanel } from "./components/admin-panel/admin-panel";

const App = () => {
  const classes = useStyles();

  return (
    <ImplementTheme>
      <CssBaseline />
      <Router>
        <main>
          <Logo className={classes.logo} />
          <Switch>
            <Route path={`/`} exact component={Login} />
            <Route path={`/admin`} component={AdminPanel} />
            <Route>
              <Redirect to={`/`} />
            </Route>
          </Switch>
        </main>
      </Router>
    </ImplementTheme>
  );
};

export default App;

const useStyles = makeStyles({
  logo: {
    width: "30vh",
    float: "left",
    margin: "3vh 0 0 5vw",
  },
});
