import TextField from "@material-ui/core/TextField";
import { UploadImage } from "../upload-image/upload-image";

export const OurReturnBox = ({ return_image, return_amount, onChange }) => {
  const handleChange = (key) => (event) => onChange(key, event.target.value);
  const handleImageChange = (key) => (value) => onChange(key, value);

  return (
    <>
      <TextField
        label="סכום ההחזר"
        value={return_amount}
        onChange={handleChange("return_amount")}
      />
      <img
        src={return_image && URL.createObjectURL(return_image)}
        alt="מסמך החזר"
        style={{ height: "60%", width: "80%" }}
      />
      <UploadImage
        onChange={handleImageChange("return_image")}
        id="returnImage"
      />
    </>
  );
};
